import React from 'react';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

const Header = () => {
  return (
    <header id="form__header--sorteo">
      <div className='barra-donaciones'>
        <img/>
        <a href="https://cafecito.app/soyfranco10" 
        target="_blank" rel="noopener noreferrer">
        <button className='barra-donaciones--boton'
        ><MonetizationOnIcon/>Donar</button>
        </a>
      </div>
      <h1 >App de Sorteo</h1>
      <div className="form__intro--sorteo">
        <div className="form__intro--epigrafresorteo">
        <p>Sorteo de uno o más ganadores 🔥
        </p>
 
        
        </div>
        
      </div>
    </header>
    
  );
};

export default Header;
