import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Stepper, Step, StepLabel } from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import confetti from "canvas-confetti";
import Confetti from "react-confetti";
import { CountDown } from "./CountDown";
import SorteoSteps from "./SorteoStep";
import Header from "./Header";

function Sorteo() {


  return (<div className="background__sorteo">
    <Header/>
    <SorteoSteps/>
    </div>
  );
}

export default Sorteo;

