import React from "react";
import "./FallingStars.scss";

export const FallingStars = () => {
  const stars = Array.from({ length: 100 }); // Puedes ajustar la cantidad de estrellas

  return (
    <div className="stars">
      {stars.map((_, index) => (
        <div
          key={index}
          className="star"
          style={{
            top: `${Math.random() * 100}vh`,
            left: `${Math.random() * 100}vw`,
            animationDelay: `${Math.random() * 5}s`,
            animationDuration: `${2 + Math.random() * 3}s`,
          }}
        />
      ))}
    </div>
  );
};