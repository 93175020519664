import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import logo from '../../Imgs/Logo.png'

const NavBar = () => {
  return (
    
    <Navbar  expand="lg" className="nav-content">
  
      <Navbar.Brand href="/">

        <img
          src={logo}
          width="160"
          height="auto"
          className="d-inline-block align-top"
          alt="Potrero Digital"
        />
              <div className='top'></div>
      </Navbar.Brand>
     {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link style={{ color: '#fff', transition: 'color 0.3s ease' }} href="#about">Perfil</Nav.Link>
          
          <Nav.Link style={{ color: '#fff', transition: 'color 0.3s ease' }} href="#contact">Contacto</Nav.Link>
        </Nav>
      </Navbar.Collapse>*/} 
    </Navbar>
   
  );
};

export default NavBar;