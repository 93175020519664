import React from 'react';

const Header = () => {
  return (
    <header>
      <h1>Hola! soy Franco</h1>
      <div className="home__intro">
        <div className="home__intro--epigrafre">
        <p>Creador de</p>
        <p>progamas simples </p>
        <p> y geniales</p>
        </div>
        <div className="home__intro--epigrafre2">
        <p>que sirven </p>
        <p>a todo el mundo.</p>
        </div>
      </div>
    </header>
    
  );
};

export default Header;
