

import imgappvoz from '../../Imgs/imgappvoz.svg'
import sorteo from '../../Imgs/sorteo.svg'
import {  Link } from 'react-router-dom';


  
const AppsArray = [
    {id: 1, nombre: "app-de-voz", img: imgappvoz, url: "/appvoz" },
    {id: 2, nombre: "app-de-sorteos", img: sorteo ,url: "/sorteo" },
   // {id: 2, nombre: "app-whatsapp", img: imgappvoz},
   //{id: 3, nombre: "app-costos", img: imgappvoz},
    // {id: 4, nombre: "app-prespuesto", img: imgappvoz},
    // {id: 5, nombre: "app-truco", img: imgappvoz}
]


export const ButtonsCards = ( ) => {

    return(
        <div className='home__apps'>
          <ul className='home__apps--ul'>

            {AppsArray.map(app => (
          <Link to={app.url}>
                <li className='home__apps--li' 
                id={`home__apps--li__${app.id}`}
                key={app.id}>

                    <div id='home__apps--li__2--img'>
                        <img
                        src={app.img}
                        alt="logo"
                        />
                    </div>

                </li> 
                </Link>))}  
              
            </ul>
        </div>
    )
}