import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Stepper, Step, StepLabel } from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import confetti from "canvas-confetti";
import Confetti from "react-confetti";
import { CountDown } from "./CountDown";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
    components: {
      MuiStepLabel: {
        styleOverrides: {
          label: {
            color: "darkgray", // Color de texto en los pasos
            "&.Mui-active": {
              color: "rgb(46, 155, 119)", // Color para el paso activo
            },
            "&.Mui-completed": {
              color: "rgb(60, 179, 139)", // Color para los pasos completados
            },
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            color: "darkgray", // Cambiado a negro para los números de los pasos
            "&.Mui-active": {
              color: "rgb(46, 155, 119)", // Ícono del paso activo sigue siendo verde
            },
            "&.Mui-completed": {
              color: "rgb(60, 179, 139)", // Ícono de paso completado sigue siendo verde
            },
          },
        },
      },
    },
  });


function SorteoSteps() {
  const [activeStep, setActiveStep] = useState(0);
  const [participants, setParticipants] = useState([]);
  const [newParticipant, setNewParticipant] = useState("");
  const [winnersCount, setWinnersCount] = useState(1);
  const [winners, setWinners] = useState([]);
  const [showConfetti, setShowConfetti] = useState(false);
  const [countdown, setCountdown] = useState(3); // Tiempo en segundos para la cuenta regresiva

  const steps = ["Ingresar participantes", "Cantidad de ganadores", "Anunciar ganador"];

  useEffect(() => {
    if (activeStep === 2 && countdown > 0) {
      const timer = setTimeout(() => setCountdown((prev) => prev - 1), 1000);
      return () => clearTimeout(timer); // Limpia el temporizador cuando el componente se desmonte o el estado cambie
    } else if (activeStep === 2 && countdown === 0) {
      handleWinnerAnnouncement();
    }
  }, [activeStep, countdown]);

  const handleNext = () => {
    if (activeStep === 1 && (winnersCount > participants.length || winnersCount < 1)) {
      Swal.fire({
        title: "Error",
        text: "La cantidad de ganadores debe ser entre 1 y la cantidad de participantes.",
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }

    if (activeStep === 1) {
      const shuffled = [...participants].sort(() => Math.random() - 0.5);
      const selectedWinners = shuffled.slice(0, winnersCount);
      setWinners(selectedWinners);
      setCountdown(3); // Reinicia la cuenta regresiva al iniciar el paso 2
    }

    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleAddParticipant = () => {
    if (newParticipant.trim() !== "" && !participants.includes(newParticipant.trim())) {
      setParticipants((prevParticipants) => [...prevParticipants, newParticipant.trim()]);
      setNewParticipant("");
    } else {
      Swal.fire({
        title: "Error",
        text: "El participante ya existe o el campo está vacío.",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  const handleWinnerAnnouncement = () => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: "¡Tenemos ganador/es!",
      html: `<strong style="color: #ff5733; font-size: 3em;">🥇${winners.join("<br />")}</strong>`,
      icon: "success",
      confirmButtonText: "¡Genial!",
    })
  };

  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const handleReset = () => {
    setActiveStep(0);
    setParticipants([]);
    setNewParticipant("");
    setWinnersCount(1);
    setWinners([]);
    setShowConfetti(false);
    setCountdown(3);
  };

  return (
    <ThemeProvider theme={theme}>
    <Box sx={{ width: "100%", padding: "20px", maxWidth: "600px", margin: "0 auto" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      

      {activeStep === 0 && (
        <>
          <TextField
       
            label="Agregar participante"
            fullWidth
            margin="normal"
            value={newParticipant}
            onChange={(e) => setNewParticipant(e.target.value.toUpperCase())} // Convierte a mayúsculas
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleAddParticipant();
                e.preventDefault();
              }
            }}
            sx={{
                '& .MuiInputLabel-root': {
                  color: 'rgb(245, 250, 247)', // Cambia el color del label a blanco
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: 'white', // Cambia el color del label cuando está enfocado
                },   '& .MuiInputBase-root': {
                    color: 'rgb(245, 250, 247)', // Cambia el color del label a blanco
                  },
              }}
          />
          <Button variant="contained"  onClick={handleAddParticipant} 
          sx={{ mt: 1, background:'rgb(46, 155, 119)' }}>
            Agregar
          </Button>
          <hr/>
          <ul  >
            {participants.map((label, index) => (
              <li key={index} className="custom-list-item">{label}</li>
            ))}
          </ul>
        </>
      )}

      {activeStep === 1 && (
        <TextField
          label="Cantidad de ganadores"
          type="number"
          fullWidth
          margin="normal"
          value={winnersCount}
          onChange={(e) => setWinnersCount(Number(e.target.value))}
          sx={{
            '& .MuiInputLabel-root': {
              color: 'rgb(245, 250, 247)', // Cambia el color del label a blanco
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: 'white', // Cambia el color del label cuando está enfocado
            },   '& .MuiInputBase-root': {
                color: 'rgb(245, 250, 247)', // Cambia el color del label a blanco
              },
          }}
        />
      )}

      {activeStep === 2 && (
        <Box>
          {countdown > 0 ? (
           <CountDown/>
          ) : (
            <ul>
              {winners.map((winner, index) => (
                <li key={index}>{winner}</li>
              ))}
            </ul>
          )}
        </Box>
      )}

      <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
        <Button disabled={activeStep === 0} 
        onClick={handleBack}
        sx={{
            color: 'gray', // Cambia el color de fondo a gris
            '&:hover': {
              color: 'darkgray', // Cambia el color al pasar el mouse
            },
          }}
      >
          Atrás
        </Button>
        {activeStep === steps.length - 1 ? (
          <Button onClick={handleReset} sx={{ mt: 1, color:'rgb(46, 155, 119)' }}>Resetear</Button>
        ) : (
          <Button onClick={handleNext} sx={{ mt: 1, color:'rgb(46, 155, 119)' }}>Siguiente</Button>
        )}
      </Box>

      {showConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} />}
    </Box>
    </ThemeProvider>
  );
}

export default SorteoSteps;